<template>
  <v-menu
    offset-y
    v-model="benefitMenu"
    :class="isCordova ? 'app-benefit-menu' : ''"
  >
    <!-- :value="totalGifts > 0" -->
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :content="totalGifts"
        :value="totalGifts > 0"
        color="success darken1"
        overlap
        offset-x="20"
        offset-y="25"
        class="font-weight-bold"
      >
        <v-btn
          :aria-label="$t('cartSummary.giftTotal')"
          class="advantages-button d-flex bg-transparent"
          medium
          tile
          icon
          v-bind="attrs"
          v-on="on"
          @click="handleButtonClick"
          @close="benefitMenu = false"
          height="50"
          :loading="loading"
        >
          <v-icon color="default">$vantaggi</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <gift-menu-content
      v-if="benefitMenu"
      :benefits="benefits"
      :giftCertificatesReceived="userGiftCertificatesReceived"
      @addUserGift="addUserGift"
    />
    <!-- @loaded="n => (totalGifts = n)" -->
  </v-menu>
</template>
<script>
import CustomService from "@/service/customService";

import deliveryReactive from "~/mixins/deliveryReactive";

import { mapActions, mapGetters } from "vuex";
import GiftMenuContent from "./GiftMenuContent.vue";

export default {
  name: "GiftMenuButton",
  components: {
    GiftMenuContent
  },
  mixins: [deliveryReactive],
  data() {
    return {
      benefits: [],
      benefitMenu: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      userGiftCertificatesReceived: "cart/getGiftCertificatesReceived"
    }),
    totalGifts() {
      return this.benefits.length + this.userGiftCertificatesReceived?.length;
    }
  },
  methods: {
    ...mapActions({
      addGiftCode: "cart/addGiftCode"
    }),
    async addUserGift(userGiftCertificateId) {
      await this.addGiftCode(userGiftCertificateId);
    },
    handleButtonClick() {
      this.benefitMenu = !this.benefitMenu;
      if (this.benefitMenu) {
        this.reload();
      }
    },
    async reload() {
      this.loading = true;
      let response = await CustomService.getBenefits();
      this.benefits = response.giftCertificateThresholds.slice(0, 3);
      this.loading = false;
    }
  },
  created() {
    this.reload();
    global.EventBus.$on("giftButtonClick", () => {
      this.handleButtonClick();
    });
  }
};
</script>
