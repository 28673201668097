<template>
  <v-card class="gift-menu-card d-flex flex-row align-center py-4" :to="link">
    <div
      class="pr-2 d-none d-sm-block"
      v-if="benefit.giftCertificate.categoryProposal.img"
    >
      <v-img
        contain
        max-width="100"
        :src="benefit.giftCertificate.categoryProposal.img"
        alt=""
      ></v-img>
    </div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="d-flex align-baseline">
        <div class="flex-grow-1 pr-8 benefit-descr" v-html="descr"></div>
        <div class="flex-shrink-0">
          <div class="text-end" v-if="benefit.giftCertificate.endDate">
            {{ $t("benefits.endDate") }}
            {{ $dayjs(benefit.giftCertificate.endDate).format("DD/MM") }}
          </div>
        </div>
      </div>
      <div
        v-for="(result, i) in benefit.giftEventThresholdResults"
        :key="'result_' + index + '_' + i"
      >
        <div
          v-if="result.currentAmount < result.thresholdAmount"
          class="flex-grow-1 font-weight-bold text-end"
        >
          {{ $n(result.currentAmount) }}/{{ result.thresholdAmount }}
          {{ result.um }}
        </div>
        <div v-else class="flex-grow-1 font-weight-bold text-end">
          {{ $t("giftCardWidget.goalAchieved") }}
        </div>
        <v-progress-linear
          :color="
            result.currentAmount < result.thresholdAmount
              ? 'warning'
              : 'success darken1'
          "
          :value="result.completionPercentage"
          aria-label="Caricamento in corso"
          rounded
          height="10"
        ></v-progress-linear>
      </div>
    </div>
  </v-card>
</template>
<script>
import get from "lodash/get";

export default {
  name: "GiftMenuCard",
  props: {
    benefit: {
      type: Object,
      required: true
    },
    index: { type: Number, required: false }
  },
  computed: {
    descr() {
      return get(this.benefit, "giftCertificate.categoryProposal.content")
        ? get(this.benefit, "giftCertificate.categoryProposal.content")
        : this.benefit.giftCertificate.description;
    },
    link() {
      if (get(this.benefit.giftCertificate, "categoryProposal.proposalUrl")) {
        return this.benefit.giftCertificate.categoryProposal.proposalUrl;
      } else if (get(this.benefit.giftCertificate, "categoryProposal.slug")) {
        return {
          name: "Category",
          params: {
            pathMatch: this.benefit.giftCertificate.categoryProposal.slug
          }
        };
      } else {
        return null;
      }
    }
  },
  methods: {
    getBarWidth(currentAmount, thresholdAmount) {
      if (currentAmount == 0) return 0;
      return (currentAmount / thresholdAmount) * 100;
    }
  }
};
</script>
