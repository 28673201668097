var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"benefits-menu"},[_c('div',{staticClass:"header success darken1"},[_vm._v(_vm._s(_vm.$t("benefits.menuTitle")))]),_c('div',{staticClass:"content pa-3"},[(
        (_vm.benefits && _vm.benefits.length > 0) ||
          (_vm.giftCertificatesReceived && _vm.giftCertificatesReceived.length > 0)
      )?[_vm._l((_vm.benefits),function(benefit,index){return _c('GiftMenuCard',{key:index,attrs:{"benefit":benefit,"index":index}})}),_vm._l((_vm.giftCertificatesReceived),function(gift){return _c('div',{key:gift.userGiftCertificateId,staticClass:"gift-certificate-card d-flex align-center py-4"},[_c('v-avatar',{staticClass:"mr-4",attrs:{"color":"secondary","size":"48","rounded":""}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(gift.amount)+"€")])]),_c('div',{staticClass:"gift-certificate-content flex-grow-1"},[_c('span',{staticClass:"d-block text-body-2 font-weight-bold mb-1"},[_vm._v(" "+_vm._s(gift.description)+" ")]),(gift.endDate)?_c('div',{staticClass:"expiration-date text-caption grey lighten-3",domProps:{"innerHTML":_vm._s(
              `<span class='font-weight-bold text-uppercase'>${_vm.$t(
                'checkout.expires'
              )}</span>: ${_vm.$t('benefits.endDate')} 
              ${_vm.$dayjs(gift.endDate).format('DD/MM')}`
            )}}):_vm._e()]),_c('v-btn',{attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.addUserGift(gift.userGiftCertificateId)}}},[_vm._v(" "+_vm._s(_vm.$t("benefits.useBtn"))+" ")])],1)})]:_c('p',{staticClass:"no-treshold"},[_vm._v(" "+_vm._s(_vm.$t("benefits.noBenefits"))+" ")]),_c('div',{staticClass:"d-flex justify-end align-center mt-6"},[_c('v-btn',{attrs:{"large":"","depressed":"","color":"primary","to":{ name: 'ProfileGift' }}},[_vm._v(" "+_vm._s(_vm.$t("benefits.viewAll"))+" ")])],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }